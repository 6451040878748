import resume from '../assets/pdf/resume.pdf'
import profileImage from '../assets/jpg/profile.jpeg'

export const headerData = {
    name: 'Reber Sincar',
    title: "SOFTWARE ENGINEER",
    desciption:"Self-driven, quick starter, passionate developer with a curious mind who enjoys solving a complex and challenging real-world problems. My goal is to always build products that provide pixel-perfect experiences. ",
    image: profileImage,
    resumePdf: resume
}
