export const educationData = [
    {
        id: 1,
        institution: 'Eramus+ Chestochowa University of Technology',
        course: 'Computer Science',
        startYear: '2020',
        endYear: '2020'
    },
    {
        id: 2,
        institution: 'Fırat University',
        course: 'Bachelor of Software Engineering',
        startYear: '2015',
        endYear: '2020'
    },
    {
        id: 3,
        institution: 'Hızır Reis Vocational and Technical Anatolian High School',
        course: 'Web Design & Programming',
        startYear: '2010',
        endYear: '2014'
    },
]