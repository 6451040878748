export const experienceData = [
    {
        id: 1,
        company: 'Ake Elevator & Escalator',
        jobtitle: 'Software Engineer',
        startYear: 'July 2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Eatvo',
        jobtitle: 'Mobile Flutter Developer',
        startYear: 'November 2020',
        endYear: 'July 2021'
    },
    {
        id: 3,
        company: 'Netaş A.Ş.',
        jobtitle: 'Engineer Intern',
        startYear: 'October 2019',
        endYear: 'January 2020'
    },
]