import eatvo from '../assets/svg/projects/eatvo.svg'
import akeElevator from '../assets/svg/projects/ake_elevator.svg'
import webrtc from '../assets/svg/projects/webrtc.svg'
import chat from '../assets/svg/projects/chat.svg'
import movie from '../assets/svg/projects/movie.svg'
import corona from '../assets/svg/projects/corona.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Eatvo',
        projectDesc: 'Eatvo is a online grocery delivery application where users can order their needs to their home, office or even to their favorite parks',
        tags: ['Flutter', 'Dart', 'GetX', 'Firebase', 'MVC'],
        code: '',
        demo: 'https://play.google.com/store/apps/details?id=com.ggbhazhoz.android',
        image: eatvo
    },
    {
        id: 2,
        projectName: 'Ake Elevator',
        projectDesc: 'Elevator Monitoring and Maintenance Mobile Aaplications',
        tags: ['Flutter', 'Dart', 'Mysql', 'Nodejs', 'AWS', 'Getx', 'Firebase', 'MQTT'],
        code: '',
        demo: 'https://play.google.com/store/apps/details?id=com.ake.elevator',
        image: akeElevator
    },
    {
        id: 3,
        projectName: 'Bussiness Chat',
        projectDesc: 'Internal messaging for companies. Text, Video, Image can be send.',
        tags: ['Flutter', 'Node.js', 'Express.js', 'Socket.IO', 'RabitMQ', 'Mysql', 'FCM'],
        code: '',
        demo: '',
        image: chat
    },
    {
        id: 4,
        projectName: 'Flutter Video Call',
        projectDesc: 'Peer to peer video and voice call',
        tags: ['Flutter', 'Nodejs', 'WEBRTC', 'Socket.IO', 'Express.js'],
        code: 'https://github.com/ReberSincar/Flutter-WebRTC-Socketio-Video-Call',
        demo: 'https://github.com/ReberSincar/Flutter-WebRTC-Socketio-Video-Call',
        image: webrtc,
    },
    {
        id: 5,
        projectName: 'FOREIGN SERIE & MOVIE',
        projectDesc: 'Watch Movies & Series with subtitles',
        tags: ['Flutter', 'Dart', 'Python', 'Firestore', 'Selenium', 'Web Scraping'],
        code: 'https://github.com/ReberSincar/Flutter-Foreign-Movie-Serie',
        demo: 'https://github.com/ReberSincar/Flutter-Foreign-Movie-Serie',
        image: movie
    },
    {
        id: 6,
        projectName: 'Corona Tracking',
        projectDesc: 'Corona virus summary and detail informations',
        tags: ['Flutter', 'Dart', 'Restful Api'],
        code: 'https://github.com/ReberSincar/Flutter-Corona-Tracking',
        demo: 'https://github.com/ReberSincar/Flutter-Corona-Tracking',
        image: corona
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/